import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl  } from '@angular/forms';
import {LoginService } from '../login/login.service';
import {Router} from '@angular/router';
import { BehaviorSubject, Observable, pipe, throwError } from 'rxjs';
import { LangService } from 'src/app/shared/lang.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public readonly postForm: FormGroup;

  constructor(private loginService: LoginService,private router: Router,private formBuilder: FormBuilder,private langService: LangService) {
    this.postForm = this.formBuilder.group({
      username: [],
    password: [],
  }); }
  errorMessage:string;
  primaryColor:string;
  logoURL: string;
  orgId:number;
  affiliation:string;

  ngOnInit() {
    let signOut = this.router.url.split('/')[2];
    if(signOut === 'Signout'){
      this.onSignOut();
    }
    else if(localStorage.getItem('currentUser')) {
      let localData = JSON.parse(localStorage.getItem('currentUser'));
      
      if(localData == null){
        localStorage.clear();
        sessionStorage.clear();
        this.router.navigate(['./login']);
      }
      else{
        let userEmail = localData['result']['user']['email'];
        let auth = localData['result']['succeeded'];
        this.router.navigate(['./quote/list']);
      }
    }
    }
    
  onSignOut() {
    this.loginService.logout();
    
    this.router.navigate(['/login']);
  }

  forgotPassword(){
    let username = this.postForm.value.username;
    if(username.length == 0){
      this.errorMessage = "Please enter a Username."
    }
    else{
    this.loginService.getUserByUserName(username).subscribe(response => {
      let localData = JSON.parse(localStorage.getItem('currentUserTable'));
      

      this.loginService.forgotPassword(response.email).subscribe();
    })
    let type; let headline;
      headline = 'Reset password link sent';
      type = 'success';
      const message = "A reset password link has been sent to the username provided.";
      Swal.fire(headline, message, type);
  }
  }

  onSubmit(postFormData) {
    this.errorMessage = '';
    this.loginService.login(postFormData).subscribe(async response =>{
    
      if(localStorage.getItem('currentUser')) {
        let localData = JSON.parse(localStorage.getItem('currentUser'));
        if(localData['result']['succeeded'] == false){
          if(localData['result']['errors'][0] == 'IncorrectDetails'){
            this.errorMessage = 'Login details are incorrect';
          }
          else{
            this.errorMessage = localData['result']['errors'][0];
          }
        }
        else{
        let userEmail = localData['result']['user']['email'];
        let auth = localData['result']['succeeded'];
        if (auth == false) {
          this.errorMessage = 'Login incorrect';
        }
        await this.loginService.getUser(userEmail).subscribe(response =>{
          let localData1 = JSON.parse(localStorage.getItem("currentUserTable"));
          
          if(localData1 == null || localData1["organizationId"] == null){
            const user = "User has encountered an error. Please contact the system administrators for assistance."
            this.errorMessage = user;
          }
          else{
            const user = "";
            this.errorMessage = '';
          }
          this.orgId = localData1["organizationId"];
         this.langService.init();
         this.loginService.getOrg(this.orgId).subscribe((response) =>{
           this.affiliation = response.affiliation;
     
           this.primaryColor = response.primaryColor;
           if(this.primaryColor){
             if(this.affiliation === "Luxaflex"){
               document.documentElement.style.setProperty('--primary', "#232323");
             }
             else{
               document.documentElement.style.setProperty('--primary', this.primaryColor);
             }
           }
           
           this.router.navigate(['./quote/list']);
         })
         this.router.navigate(['./login']);
        });
        }
      }
    },
      (error) =>{
        console.error('error caught in component');
        const user = 'User does not exist';
        const pass = 'Password';
        if(error.error.includes(user)){
          this.errorMessage = 'User is incorrect';
        }
        else{this.errorMessage = error.error;}
        if(error.error.includes(pass)){
          this.errorMessage = 'Password is incorrect!';
        }
      });

}

}
