import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ILogin } from '../app/interfaces/ILogin';
import { IUserList } from '../app/interfaces/IUser-list';
import { OrganizationsModel } from '../app/interfaces/Iorganization-list';
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
};
@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private currentUserSubject: BehaviorSubject<IUserList>;
  public currentUser: Observable<IUserList>;

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<IUserList>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
   }
   public get currentUserValue(): IUserList {
    return this.currentUserSubject.value;
}
  login(response): Observable<any> {
    return this.http.post<any>('/api/auth/Login', response ,httpOptions)
    .pipe(map(user => {
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);
        return user;
    }));
  }

  forgotPassword(email){
    return this.http.get<string>('/api/auth/ForgotPassword/' + email);
  }

  resetPassword(model){
    return this.http.post<any>('/api/auth/ResetPassword/',model, httpOptions);
  }

  getOrg(orgId): Observable<any> {
    return this.http.get<OrganizationsModel>('/api/Organization/' + orgId);
  }
  getUser(userEmail): Observable<any> {
    return this.http.get<any>('api/User/ByEmail/' + userEmail)
    .pipe(map(user => {
      localStorage.setItem('currentUserTable', JSON.stringify(user));
      
      this.currentUserSubject.next(user);
      return user;
    }));
  }
  getUserByUserName(userName): Observable<any> {
    return this.http.get<any>('api/User/ByUsername/' + userName)
    .pipe(map(user => {
      localStorage.setItem('currentUserTable', JSON.stringify(user));
      this.currentUserSubject.next(user);
      return user;
    }));
  }
    logout() {
      // remove user from local storage to log user out
      localStorage.clear();
      sessionStorage.clear();
      this.currentUserSubject.next(null);
  }
  }

