import { Component, OnInit, Renderer2, AfterViewInit } from '@angular/core';
import { LangService } from './shared/lang.service';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { AppService } from './app.service.ts';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})

@Injectable()
export class AppComponent implements OnInit, AfterViewInit {
  isMultiColorActive = environment.isMultiColorActive;
  constructor(private langService: LangService, private renderer: Renderer2, private appService: AppService) {

  }
  primaryColor:string;
  logoURL: string;
  orgId:number;
  affiliation:string;

  ngOnInit() {
     let localData1 = JSON.parse(localStorage.getItem("currentUserTable"));
     this.orgId = localData1["organizationId"];
    this.langService.init();
    this.appService.getOrg(this.orgId).subscribe((response) =>{
      this.affiliation = response.affiliation;

      this.primaryColor = response.primaryColor;
      if(this.primaryColor){
        if(this.affiliation === "Luxaflex"){
          document.documentElement.style.setProperty('--primary', "#232323");
        }
        else{
          document.documentElement.style.setProperty('--primary', this.primaryColor);
        }
      }
    })
    
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.renderer.addClass(document.body, 'show');
    }, 1000);
    setTimeout(() => {
      this.renderer.addClass(document.body, 'default-transition');
    }, 1500);
    this.changeColor("blue");
  }
  changeColor(color:string){
    document.documentElement.style.setProperty('&color-red-main', color);
 }
}
