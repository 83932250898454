<div class="page-bg" style="background: url(assets/img/blinds/login-bg.png) center center /cover no-repeat">
  <div class="absolute-content">
    <div class="auto-form-wrapper">
      <div class="logo-image" style="display: flex; align-items: center; justify-content: center; margin: 0 auto 3rem;">
        <img src="assets/img/blinds/logo.png" alt="Logo image" style="width:400px;">
      </div>
      <form [formGroup]="postForm" (ngSubmit)="onSubmit(postForm.value)" method="post">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <p>
                Please enter your username and password below to log in.
              </p>
              <div class="form-group">
                <input type="text" formControlName="username" class="form-control" title="" placeholder="Username" value="">
              </div>
              <div class="form-group">
                <input type="password" formControlName="password" class="form-control" title="" placeholder="Password">
              </div>
              <!-- <span *ngIf="errorMesage !== ''">{{errorMesage}}</span> -->
              <span style="color:red;font-size:14px" [innerHTML]="errorMessage"></span>
              <br>
              <p>
                <a (click)="forgotPassword()" style="cursor:pointer;">Forgot password?</a>
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <button type="submit" class="btn btn-secondary">SIGN IN</button>
            </div>
          </div>
        </div>
      </div>
      </form>
    </div>
  </div>
</div>
