<div class="page-bg" style="background: url(/assets/img/blinds/login-bg.png) center center /cover no-repeat">
  <div class="absolute-content transparent">
    <div class="auto-form-wrapper">
      <div class="logo-image">
        <img src="/assets/img/blinds/blindquip-logo.png" alt="Logo image">
      </div>
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <p>
                Please enter your username and password below to log in.
              </p>
              <div class="form-group">
                <input type="text" class="form-control" title="" placeholder="Username" value="">
              </div>
              <div class="form-group">
                <input type="password" class="form-control" title="" placeholder="Password">
              </div>
              <p>
                <a href="">
                  Forgot password?
                </a>
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <button routerLink="../app" type="button" class="btn btn-secondary">SIGN IN</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
