import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl  } from '@angular/forms';
import {LoginService } from '../login/login.service';
import {ActivatedRoute, Router} from '@angular/router';
import { BehaviorSubject, Observable, pipe, throwError } from 'rxjs';
import { LangService } from 'src/app/shared/lang.service';
import Swal from 'sweetalert2';
import { PathLocationStrategy } from '@angular/common';
import { timestamp } from 'rxjs/operators';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {
  public readonly postForm: FormGroup;

  email: string;
  errPassword: string;
  password: string;
  passwordCheck: string;
  canSubmit: boolean = false;
  token: string;

  constructor(private loginService: LoginService,private router: Router, private activatedRoute: ActivatedRoute ,private formBuilder: FormBuilder,private langService: LangService) {
    this.postForm = this.formBuilder.group({
      passwordCheck: []
  }); }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.email = params['email'];
      this.token = params['token'];
  });
  }

  checkPassword(data){
    this.password = data;
    this.passwordValidator();
  }
  checkPassword1(data){
    this.passwordCheck = data;
    this.passwordValidator();
  }
  passwordValidator(){
    if(this.password.length < 8 || this.passwordCheck.length < 8){
      this.errPassword = "Password needs to be longer than 8 characters";
      this.canSubmit = false;
    }
    else if (this.password != this.passwordCheck){
      this.errPassword = "Passwords do not match!";
      this.canSubmit = false;
    }
    else if (this.password == this.passwordCheck){
      this.errPassword = "";
      this.canSubmit = true;
    }
    else{
      this.errPassword = "";
      this.canSubmit = true;
    }
  }
  onSubmit(postFormData) {
    let model ={
      Email: this.email,
      Password: this.passwordCheck,
      Token: this.token
    }
    this.loginService.resetPassword(model).subscribe(response =>{
      let type; let headline;
      var string = JSON.stringify(response);
      var obj = JSON.parse(string);
      if (obj.result.succeeded == true)
      {
        headline = 'User Password Updated';
        type = 'success';
        const message = "User Password Updated!";
        Swal.fire(headline, message, type);
      }
      else {
        headline = 'Error';
        type = 'error';
        const message = "User Password not Updated!";
        Swal.fire(headline, message, type);
      }
    })
  }

}
