import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ErrorComponent } from './error/error.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { environment } from 'src/environments/environment';
import { HomeComponent } from './home/home.component';
import { AuthGuard } from '../shared/auth.guard';
import { UserRole } from '../shared/auth.roles';
import {LoginAlternativeComponent} from './login-alternative/login-alternative.component';
import {LoginComponent} from './login/login.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';

const adminRoot = environment.adminRoot.substr(1); // path cannot start with a slash

let routes: Routes = [
  // {
  //   path: '',
  //   redirectTo: 'app',
  //   pathMatch: 'full'
  // },
  {
    path: '',
    loadChildren: () => import('./app/app.module').then((m) => m.AppModule),
    data: { roles: [UserRole.Admin, UserRole.User, UserRole.Owner, UserRole['Sales Rep']] },
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'user',
    loadChildren: () => import('./user/user.module').then((m) => m.UserModule),
  },
  { path: 'error', component: ErrorComponent },
  { path: 'login-alternative', component: LoginAlternativeComponent },
  {path: "User/ResetPassword", component: PasswordResetComponent},
  { path: 'login', component: LoginComponent },
  {
    path: 'external-view/:data',
    loadChildren: () =>
      import('./external-view/external-view.module').then(
        (m) => m.ExternalViewModule
      ),
  },
  {
    path: 'external-view-complete/:data',
    loadChildren: () =>
      import('./external-view-complete/external-view-complete.module').then(
        (m) => m.ExternalViewCompleteModule
      ),
  },
  {
    path: 'payments',
    loadChildren: () =>
      import('./payments/payments.module').then(
        (m) => m.PaymentsModule
      ),
  },
  { path: 'login/Signout', component: LoginComponent },
  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: '**', redirectTo: '/error' },
];

if (!environment.isAuthGuardActive) {
  routes = [
    // {
    //   path: '',
    //   redirectTo: 'app',
    //   pathMatch: 'full'
    // },
    {
      path: '',
      loadChildren: () => import('./app/app.module').then((m) => m.AppModule),
    },
    {
      path: 'user',
      loadChildren: () =>
        import('./user/user.module').then((m) => m.UserModule),
    },
    { path: 'error', component: ErrorComponent },
    { path: '**', redirectTo: '/error' },
  ];
}
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ViewRoutingModule {}
