import { Injectable } from '@angular/core';
import { OrganizationsModel } from '../app/views/app/interfaces/Iorganization-list';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class AppService {
  constructor(private http: HttpClient) { }

  getOrg(orgId): Observable<any> {
    return this.http.get<OrganizationsModel>('/api/Organization/' + orgId);
  }
}




