<div class="page-bg" style="background: url(assets/img/blinds/login-bg.png) center center /cover no-repeat">
 <div class="absolute-content">
   <div class="auto-form-wrapper">
     <div class="logo-image">
       <img src="assets/img/blinds/blindquip-logo.png" alt="Logo image">
     </div>
     <form [formGroup]="postForm" (ngSubmit)="onSubmit(postForm.value)" method="post">
     <div class="card">
       <div class="card-body">
         <div class="row">
           <div class="col-12">
            <p>
              Reset password
            </p>
             <div class="form-group">
               <input type="password" class="form-control" title="" (input)="checkPassword($event.target.value)" placeholder="New password" value="">
             </div>
             <div class="form-group">
               <input type="password" formControlName="passwordCheck" (input)="checkPassword1($event.target.value)" class="form-control" title="" placeholder="Confirm Password">
             </div>
             <!-- <span *ngIf="errorMesage !== ''">{{errorMesage}}</span> -->
             <span style="color:red;font-size:14px" [innerHTML]="errPassword"></span>
           </div>
           <br>
         </div>
         <div class="row">
           <div class="col-12">
             <button type="submit" class="btn btn-secondary" [disabled]="canSubmit == false">Reset Password</button>
           </div>
         </div>
       </div>
     </div>
     </form>
   </div>
 </div>
</div>
